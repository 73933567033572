import ComingSoon from 'components/CommingSoon';
import React from 'react';

const Auction: React.FC = () => {
  return (
   <><ComingSoon/></>
  );
};

export default Auction;
