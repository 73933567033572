import React from 'react';
import { Typography, Row, Col, Button, Input, Divider } from 'antd';
import SocialMediaLinks from './SocialMediaLinks';

const { Title, Paragraph } = Typography;

const ComingSoon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#FFD700',
        padding: '20px',
      }}
    >
      <Title level={1} style={{ color: '#FFD700', fontSize: '48px' }}>
        Coming Soon
      </Title>
      <Divider style={{ borderColor: 'gold', marginBottom: '20px' }} />

      <Paragraph style={{ color: '#fff', fontSize: '18px', marginBottom: '40px' }}>
        We are working hard to bring you a great experience. Stay tuned!
      </Paragraph>
      
      {/* Optional Countdown Timer (You can add a countdown component here if needed) */}
      
    
      <Paragraph style={{ color: '#fff', fontSize: '14px' }}>
        Follow us on social media for updates.
      </Paragraph>
      <SocialMediaLinks usePatternOne={true}/>
    </div>
  );
};

export default ComingSoon;
