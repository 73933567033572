// /src/components/Craft.tsx
import React from 'react';
import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import img1 from "../../assets/img/crafts/1.jpg";
import img2 from "../../assets/img/crafts/2.jpg";
import img3 from "../../assets/img/crafts/3.jpg";
import img4 from "../../assets/img/crafts/4.jpg";
import img5 from "../../assets/img/crafts/5.jpg";
import img6 from "../../assets/img/crafts/6.jpg";
import img7 from "../../assets/img/crafts/7.jpg";
import img8 from "../../assets/img/crafts/8.jpg";
import img9 from "../../assets/img/crafts/9.jpg";
import img10 from "../../assets/img/crafts/10.jpg";
import img11 from "../../assets/img/crafts/11.jpg";
import img12 from "../../assets/img/crafts/12.jpg";
import img13 from "../../assets/img/crafts/13.jpg";
import img14 from "../../assets/img/crafts/14.jpg";

// // Store the images in an array for easy access
const craftImages = [
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14
];

const craftItems = [
  { id: 1, title: 'Craft', thumb: img1, images: craftImages }
];


const Craft: React.FC = () => {
  const navigate = useNavigate();

  const handleCraftClick = (CraftData: any) => {
    navigate(`/media/crafts/${CraftData.id}`, { state: { Craft: CraftData } });
  };

  return (
    <Row gutter={[32, 32]} justify="center">
      {craftItems.map(item => (
        <Col xs={24} sm={12} md={8} lg={8} key={item.id}>
          <Card
            hoverable
            onClick={() => handleCraftClick(item)}
            style={{ backgroundColor: '#333', border: '1px solid #FFD700', textAlign: 'center', color: '#FFD700' }}
            cover={
              <div style={{ position: 'relative' }}>
                <img alt={item.title} src={item.thumb} style={{ height: '500px', objectFit: 'cover', width: '100%' }} />
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  padding: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: '#FFD700',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.title}</span>
                </div>
              </div>
            }
          />
        </Col>
      ))}
    </Row>
  );
};
export default Craft;
