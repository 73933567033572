import React from 'react';

const LocationIframe: React.FC = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163512.6323643474!2d55.14900462546415!3d25.09668678974925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sbd!4v1681334303671!5m2!1sen!2sbd"
      width="250px"
      height="200px"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
    />
  );
};

export default LocationIframe;
