import React, { useState, useEffect } from 'react';
import { Menu, Drawer, Button, Space } from 'antd';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import './css/ResponsiveMenu.css'; // Import custom CSS for styling
import logo from '../assets/img/logo_b.png';

// Define props for the ResponsiveMenu component
interface ResponsiveMenuProps {
  footerOnly?: boolean; // Controls whether to filter only footer items
}

const ResponsiveMenu: React.FC<ResponsiveMenuProps> = ({ footerOnly = false }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Get the current route path
  const location = useLocation();
  const currentPath = location.pathname;

  // Shared Menu items
  const menuItems = [
    { key: '/', label: 'Home', link: '/' },
    { key: '/origin', label: 'Origin', link: '/origin' },
    {
      key: '/brandz',
      label: 'Brandz',
      link: '/brandz',
      subItems: [
        { key: '/brandz/1972', label: '1972', link: 'https://www.facebook.com/OutLiner.zenana/' ,external: true},
        { key: '/brandz/1972-conscious', label: '1972 Conscious', link: 'https://www.1972conscious.com/',external: true },
        { key: '/brandz/zenena', label: 'Zenena', link: 'https://www.facebook.com/people/Zenana/100054405284831/?mibextid=LQQJ4d',external: true },
        { key: 'external-1', label: 'Meta Moina', link: 'http://www.metamoina.io', external: true, footer: true },
        { key: 'external-2', label: 'Niharika Momtaz Production', link: 'https://www.facebook.com', external: true, footer: true },
      ],
    },
    { key: '/media', label: 'Media', link: '/media' },
    { key: '/newsroom', label: 'NewsRoom', link: '/newsroom', footer: true },
    { key: '/collaboration', label: 'Collaboration', link: '/collaboration', footer: true },
    { key: '/auction', label: 'Auction', link: '/auction', footer: true },
    { key: 'external-3', label: 'Bangladesh Art Week', link: 'https://www.bangladeshartweek.com/', external: true, footer: true },
    { key: 'external-4', label: 'Heritage Bangladesh Foundation', link: 'https://www.heritagebangladeshfoundation.com/', external: true, footer: true },
    { key: '/contact', label: 'Contact', link: '/contact' },
  ];

  // Filter the items based on footerOnly prop
  const filteredItems = footerOnly ? menuItems.filter(item => item.footer) : menuItems;

  // Handle screen resizing for responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  const renderMenuItems = () =>
    filteredItems.map((item) =>
      item.subItems ? (
        <Menu.SubMenu key={item.key} title={<RouterLink to={item.link} style={{ color: 'white' }}>{item.label}</RouterLink>} >
          {item.subItems.map((subItem) => (
            <Menu.Item key={subItem.key}>
              {subItem.external ? (
                <a href={subItem.link} target="_blank" rel="noopener noreferrer">
                  {subItem.label}
                </a>
              ) : (
                <RouterLink to={subItem.link}>{subItem.label}</RouterLink>
              )}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={item.key}>
          {item.external ? (
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.label}
            </a>
          ) : (
            <RouterLink to={item.link}>{item.label}</RouterLink>
          )}
        </Menu.Item>
      )
    );

  return (
    <>
      {isMobile ? (
        <>
          <Button
            type="primary"
            icon={<MenuOutlined />}
            onClick={showDrawer}
            style={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}
          />
          <Drawer
            title="Menu"
            placement="right"
            onClose={closeDrawer}
            open={drawerVisible}
            styles={{ 
              header: {color:'white' , backgroundColor:"#333333" },
             }}
            closeIcon = {<CloseOutlined style={{color:"white"}}/>}
           
          >
            <Space direction='vertical' align={'center'} >

            <Menu
              mode="inline"
              selectedKeys={[currentPath]}
              onClick={closeDrawer} // Close the drawer when an item is clicked
              className="custom-responsive-menu"
            >
              {renderMenuItems()}
            </Menu>
          
            </Space>
            
          </Drawer>
        </>
      ) : (
        <Menu
          mode="horizontal"
          selectedKeys={[currentPath]}
          className="custom-responsive-menu"
          style={{ justifyContent: 'center' }}
        >
          {renderMenuItems()}
        </Menu>
      )}
    </>
  );
};

export default ResponsiveMenu;
