import { Typography } from 'antd';
const { Title } = Typography;
interface CustomTitleProps {
    text: string;
  }
  
const CustomTitle : React.FC<CustomTitleProps> = ({text}) =>{ 
    return <Title level={4} style={{ color: 'white' }} >{text} </Title>
   
}

export default CustomTitle;

