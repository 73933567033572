// /src/components/CraftDetails.tsx
import React from 'react';
import { Button, Typography, Card, Row, Col } from 'antd';
import { useLocation, useNavigate, } from 'react-router-dom';

const { Title } = Typography;

const CraftDetails: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const Craft = location.state?.Craft;

    if (!Craft) {
        return <p>Error: Craft details not available.</p>;
    }

    const { title, images } = Craft;

    return (
        <div style={{ color: '#FFD700' }}> {/* Gold text color */}
            <Button
                onClick={() => navigate("/media/")}
                style={{
                    margin: '20px auto',
                    display: 'block',
                    backgroundColor: '#FFD700',
                    color: '#333',
                    borderColor: '#FFD700',
                    fontWeight: 'bold',
                }}
                type="primary"
                shape="round"
                size="large"
            >
                Back to Media
            </Button>
            <Title level={2} style={{ color: '#FFD700', textAlign: 'center' }}>{title}</Title>

            {/* Card View for Images */}
            <div style={{ marginTop: '20px', padding: '20px' }}>
                <Row gutter={[16, 16]} justify="center">
                    {images.map((image: string, index: number) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={index}>
                            <Card
                                hoverable
                                cover={<img alt={`Craft Image ${index + 1}`} src={image} style={{ height: '400px', objectFit: 'cover' }} />}
                                style={{
                                    backgroundColor: '#333',
                                    color: '#FFD700',
                                    border: '1px solid #FFD700',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                }}
                            >
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>

    );
};


export default CraftDetails;
