import { Layout, Row, Col, Typography, Space } from 'antd';
import "./css/Footer.css";

import SocialMediaLinks from './SocialMediaLinks';
import CustomDivider from './CustomDivider';
import CustomTitle from './CustomTitle';
import LocationIframe from './Location';
import { EnvironmentOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';

const {  Text } = Typography;
const { Footer: AntdFooter } = Layout;



const brands = [
  { text: '1972', url: 'https://www.facebook.com/OutLiner.zenana/' },
  { text: '1972 Conscious', url: 'https://www.facebook.com/1972Conscious?mibextid=LQQJ4dn' },
  { text: 'Zenana', url: 'https://www.facebook.com/people/Zenana/100054405284831/?mibextid=LQQJ4d' },
  { text: 'Meta Moina', url: 'http://www.metamoina.io' },
  { text: 'Niharika Momtaz Production', url: 'https://www.facebook.com' },
];

const quickLinks = [
  
  // Adding items from menuItems with footer: true
  { text: 'NewsRoom', url: '/newsroom' },
  { text: 'Collaboration', url: '/collaboration' },
  { text: 'Auction', url: '/auction' },
  { text: 'Bangladesh Art Week', url: 'https://www.bangladeshartweek.com/', external: true },
  { text: 'Heritage Bangladesh Foundation', url: 'https://www.heritagebangladeshfoundation.com/', external: true },
];
const Footer: React.FC = () => {
  return (
    <AntdFooter style={{ backgroundColor: '#0f0f0f', padding: '20px 100px', color: 'white' }}>
      <CustomDivider />
    
        <Row justify="center" >
          {/* Contact Info Column */}
          <Col xs={24} sm={12} md={4} lg={4 } style={{ textAlign: 'center' }}>
            <CustomTitle text="Contact" />
            <Space direction="vertical" size={4}>
            <Text style={{ color: 'white', fontWeight: 'bold' }}>
              <EnvironmentOutlined /> Dubai
            </Text>
            <Text style={{ color: 'white' }}>
              <PhoneOutlined /> <a href="tel:+971551785800" style={{ color: 'white' }}>+971 55 178 5800</a>
            </Text>
            <Text style={{ color: 'white', fontWeight: 'bold' }}>
              <EnvironmentOutlined /> Dhaka
            </Text>
            <Text style={{ color: 'white' }}>
              <PhoneOutlined /> <a href="tel:+8801711564862" style={{ color: 'white' }}>+880 171 156 4862</a>
            </Text>
            <Text style={{ color: 'white' }}>
              <MailOutlined /> <a href="mailto:niharika.momtaz@gmail.com" style={{ color: 'white' }}>niharika.momtaz@gmail.com</a>
            </Text>
          </Space>
          </Col>

          {/* Brands Column */}
          <Col xs={24} sm={12} md={4} lg={4 } style={{ textAlign: 'center' }}>
            <CustomTitle text="Brands" />
            <Space direction="vertical" size={4}>
            {brands.map((brand, index) => (
                <a
                  key={index}
                  href={brand.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  {brand.text}
                </a>
              ))}
            </Space>
          </Col>

          {/* Quick Links Column */}
          <Col xs={24} sm={12} md={4} lg={4 } style={{ textAlign: 'center' }}>
            <CustomTitle text="Quick Links" />
            <Space direction="vertical" size={4}>
            {quickLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}  
                  className="footer-link"
                >
                  {link.text}
                </a>
              ))}
            </Space>
          </Col>

          {/* Social Media Column */}
          <Col xs={24} sm={12} md={4} lg={4 } style={{ textAlign: 'center' }}>
            <CustomTitle text="Follow Us" />
            <Space direction="vertical" size={4} style={{ alignItems: 'center' }}>
              <SocialMediaLinks usePatternOne={true} />
              <LocationIframe />
            </Space>
          </Col>
        </Row>
     
    </AntdFooter>
  );
};
export default Footer;
