import React from 'react';
import { Button, Divider, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate('/');
    };
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#FFD700',
                padding: '20px',
            }}
        >
            <Divider style={{ borderColor: 'gold', margin: '0 auto' }} />
            <Title level={1} style={{ color: '#FFD700', fontSize: '72px' }}>
                404
            </Title>
            <Title level={2} style={{ color: '#FFD700', marginBottom: '20px' }}>
                Page Not Found
            </Title>
            <Paragraph style={{ color: '#fff', fontSize: '18px', marginBottom: '40px' }}>
                Sorry, the page you are looking for does not exist.
            </Paragraph>
            <Button
                type="primary"
                onClick={goHome}
                style={{ backgroundColor: '#FFD700', borderColor: '#FFD700', color: '#1f1f1f' }}
            >
                Go Back Home
            </Button>
        </div>
    );
};

export default NotFound;
