import React, { useState } from 'react';
import { Row, Col, Typography, Button, Space, Divider } from 'antd';
import "../assets/css/custom.css"; // Assuming custom styles are here
import propic from '../assets/img/niharikaprofile.jpg';
import SocialMediaLinks from 'components/SocialMediaLinks';
import CustomDivider from 'components/CustomDivider';

const { Title, Paragraph } = Typography;

const Biography: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  // Sample content text
  const fullText = `
    Niharika Momtaz's rise as an entrepreneur, art connoisseur, fashion and jewelry designer is a story befitting a fairytale. Hailing from an upscale neighborhood of Chittagong, Bangladesh, Niharika stepped into the domain of clothing design at the very early age of 18! The stint was brief and after having veered into the world of corporate finance for a decade, she has once again resumed her passionate path by designing jewelry and fashion labels. Came year 2012 and her jewelry labels Outliner and 1972 Couture jewelry were launched from this inspiration. Point to note that as a profession she did her Chartered Accountancy Article ship with KPMG Bangladesh. 
    She worked for an NGO called ‘Acid Survivors Foundation’ As Head of Finance & Fund Raising from 2000 to 2008. Since the beginning of her journey as a designer, she enjoyed making wearable art including sculptural Jewelry. For a community Bangladesh art week project called ‘Shikor’, first time she designed free standing sculptures for this particular project involving local artisans. Niharika has showcased her collections at various international fashion shows in London, Turkey, Colombo, Cannes, Singapore, Dubai, Doha, Dhaka and Chattogram. 
    She also has arranged a number of photo exhibitions, art exhibitions and fashion shows in different capacities. She was always keen to express her designing skills and interest in art.
    Niharika’s work has been published in UAE –The National; The News, Srilanka; Emma Mag Golden Issue, France; Vogue Mini, China; Meta News; The Daily Star; The Business Standard; Ice Today, among others.
    She is a founder of a pioneering an NFT Media “Meta Moina” focusing on generative art (interactive design media, new media art , digital art and crypto).
    Her other endeavor is to promote art, culture & Heritage. She is the founder of Bangladesh Art Week (BAW) and Heritage Bangladesh Foundation.
  `;
  const shortText = fullText.substring(0, 500) + "...";

  return (
    <div >
      <Divider />
      <Row gutter={[32, 32]} align="top" >
        {/* Image Column */}
        <Col xs={24} sm={24} md={10} lg={8}  >
          <img
            src={propic} // Update with your actual image path
            alt="Niharika Momtaz"
            style={{ width: '100%', borderRadius: '8px', color: 'white' }}
          />
          <div style={{ marginTop: '20px' }}>
            <Title level={5} style={{ color: 'white' }}>Follow us on :</Title>
            <Space size="middle">
              <SocialMediaLinks usePatternOne={true} />
            </Space>
          </div>
        </Col>
        {/* Content Column */}
        <Col xs={24} sm={24} md={14} lg={16}>
          <Title level={2} style={{ color: '#FFD700' }}>NIHARIKA MOMTAZ</Title>

          <Paragraph style={{ color: 'white', lineHeight: '1.8', textAlign: 'justify' }}>
            {expanded ? fullText : shortText}
          </Paragraph>
          <Button
            type="link"
            onClick={() => setExpanded(!expanded)}
            style={{ color: '#FFD700' }}
          >
            {expanded ? 'Read less' : 'Read more'}
          </Button>
        </Col>
      </Row>
    </div>

  );
};

export default Biography;
