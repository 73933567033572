// import React, { useState } from 'react';
// import { Card, Row, Col, Button, Typography,Divider} from 'antd';
// import GallerySlider from 'components/GallerySlider';

// const { Title } = Typography;

// interface MediaItem {
//   id: number;
//   title: string;
//   imageUrl: string;
//   tags: string[];
// }

// const mediaItems: MediaItem[] = [
//   { id: 1, title: 'Image 1', imageUrl: 'https://via.placeholder.com/300', tags: ['Events', 'Crafts'] },
//   { id: 2, title: 'Image 2', imageUrl: 'https://via.placeholder.com/300', tags: ['Celebrity', 'Events'] },
//   { id: 3, title: 'Image 3', imageUrl: 'https://via.placeholder.com/300', tags: ['Crafts', 'Events'] },
//   { id: 4, title: 'Image 4', imageUrl: 'https://via.placeholder.com/300', tags: ['Crafts', 'Celebrity'] },
//   { id: 5, title: 'Image 5', imageUrl: 'https://via.placeholder.com/300', tags: ['Events', 'Celebrity'] },
//   { id: 6, title: 'Image 6', imageUrl: 'https://via.placeholder.com/300', tags: ['Events', 'Celebrity'] },
// ];

// const allTags = Array.from(new Set(mediaItems.flatMap(item => item.tags)));

// const Media: React.FC = () => {
//   const [selectedTag, setSelectedTag] = useState<string | null>(null);

//   const filteredItems = selectedTag
//     ? mediaItems.filter(item => item.tags.includes(selectedTag))
//     : mediaItems;

//   return (
//     <div style={{ }}>
//       <Title level={2} style={{ textAlign: 'center', color: '#FFD700' }}>
//         Media
//       </Title>
//       <Divider style={{ borderColor: 'gold', margin: '0 auto' }} />

//       <div style={{ textAlign: 'center', marginTop:'20px',marginBottom: '20px' }}>
//       <Button
//           key="all"
//           type={selectedTag === null ? 'primary' : 'default'}
//           onClick={() => setSelectedTag(null)}
//           style={{
//             margin: '5px',
//             backgroundColor: selectedTag === null ? '#FFD700' : '#333',
//             color: selectedTag === null ? '#000' : '#FFD700',
//             borderColor: 'gold',
//           }}
//         >
//           All
//         </Button>
//         {allTags.map(tag => (
//           <Button
//             key={tag}
//             type={selectedTag === tag ? 'primary' : 'default'}
//             onClick={() => setSelectedTag(tag)}
//             style={{
//               margin: '5px',
//               backgroundColor: selectedTag === tag ? '#FFD700' : '#333',
//               color: selectedTag === tag ? '#000' : '#FFD700',
//               borderColor: 'gold',
//             }}
//           >
//             {tag}
//           </Button>
//         ))}
//       </div>
//       <>
//       <GallerySlider/>
//       </>
//       <Row gutter={[32, 32]} justify="center">
//         {filteredItems.map(item => (
//           <Col xs={24} sm={12} md={8} lg={6} key={item.id}>
//             <Card
//               hoverable
//               style={{
//                 backgroundColor: '#333',
//                 border: '1px solid #FFD700',
//                 textAlign: 'center',
//                 color: '#FFD700',
//               }}
//               cover={<img alt={item.title} src={item.imageUrl} style={{ height: '200px', objectFit: 'cover' }} />}
//             >
//               <Card.Meta title={<span style={{ color: '#FFD700' }}>{item.title}</span>} />
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </div>
//   );
// };

// export default Media;


// /src/components/Gallery.tsx
import React, { useState } from 'react';
import { Button, Typography, Divider } from 'antd';
import Event from './media/events';
import Craft from './media/craft';
import Celebrity from './media/celebredity';

const { Title } = Typography;

const Media: React.FC = () => {
  const [selectedComponent, setSelectedComponent] = useState<string | null>('Event');

  return (
    <div>
      <Title level={2} style={{ textAlign: 'center', color: '#FFD700' }}>Gallery</Title>
      <Divider style={{ borderColor: 'gold', margin: '0 auto',marginBottom: '5px' }} />

      <div style={{ textAlign: 'center',  marginBottom: '20px' }}>
        <Button
          key="events"
          type={selectedComponent === 'Event' ? 'primary' : 'default'}
          onClick={() => setSelectedComponent('Event')}
          style={{
            margin: '5px',
            backgroundColor: selectedComponent === 'Event' ? '#FFD700' : '#333',
            color: selectedComponent === 'Event' ? '#000' : '#FFD700',
            borderColor: 'gold',
          }}
        >
          Events
        </Button>
        <Button
          key="crafts"
          type={selectedComponent === 'Craft' ? 'primary' : 'default'}
          onClick={() => setSelectedComponent('Craft')}
          style={{
            margin: '5px',
            backgroundColor: selectedComponent === 'Craft' ? '#FFD700' : '#333',
            color: selectedComponent === 'Craft' ? '#000' : '#FFD700',
            borderColor: 'gold',
          }}
        >
          Crafts
        </Button>
        <Button
          key="celebrity"
          type={selectedComponent === 'Celebrity' ? 'primary' : 'default'}
          onClick={() => setSelectedComponent('Celebrity')}
          style={{
            margin: '5px',
            backgroundColor: selectedComponent === 'Celebrity' ? '#FFD700' : '#333',
            color: selectedComponent === 'Celebrity' ? '#000' : '#FFD700',
            borderColor: 'gold',
          }}
        >
          Celebrity
        </Button>
      </div>

      {/* Render components conditionally based on selectedComponent */}
      {selectedComponent === 'Event' && <Event />}
      {selectedComponent === 'Craft' && <Craft />}
      {selectedComponent === 'Celebrity' && <Celebrity />}
    </div>
  );
};

export default Media;
