// /src/components/Event.tsx
import React from 'react';
import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import thumb1 from "../../assets/img/events/thumbs/1.Istanbul Modest Fashion Week 2024.jpg";
import thumb2 from "../../assets/img/events/thumbs/2.Enchanting Fabrics Uniting Cultures 2023.jpg";
import thumb3 from "../../assets/img/events/thumbs/3.Milano Jewelry Week 2023.jpg";
import thumb4 from "../../assets/img/events/thumbs/4.Massira Inclusive Srilanka 2018.jpg";
import thumb5 from "../../assets/img/events/thumbs/5.Men Fashion Week Nigeria 2021.jpg";
import thumb6 from "../../assets/img/events/thumbs/6.Red Carpet Week 71st Cannes Film Festival 2018.jpg";

import istanbunImg1 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/1.jpg";
import istanbunImg2 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/2.jpg";
import istanbunImg3 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/3.jpg";
import istanbunImg4 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/4.jpg";
import istanbunImg5 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/5.jpg";
import istanbunImg6 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/6.jpg";
import istanbunImg7 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/7.jpg";
import istanbunImg8 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/8.jpg";
import istanbunImg9 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/9.jpg";
import istanbunImg10 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/10.jpg";
import istanbunImg11 from "../../assets/img/events/1.Istanbul Modest Fashion Week 2024/11.jpg";

import fabricImg1 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/1.jpeg";
import fabricImg2 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/2.jpeg";
import fabricImg3 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/3.jpeg";
import fabricImg4 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/4.jpeg";
import fabricImg5 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/5.jpeg";
import fabricImg6 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/6.jpeg";
import fabricImg7 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/7.jpeg";
import fabricImg8 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/8.jpeg";
import fabricImg9 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/9.jpeg";
import fabricImg10 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/10.jpeg";
import fabricImg11 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/11.jpeg";
import fabricImg12 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/12.jpeg";
import fabricImg13 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/13.jpeg";
import fabricImg14 from "../../assets/img/events/2.Enchanting Fabrics Uniting Cultures 2023/14.jpeg";

import MillanoImg1 from "../../assets/img/events/3.Milano Jewelry Week 2023/1.jpg";
import MillanoImg2 from "../../assets/img/events/3.Milano Jewelry Week 2023/2.jpg";
import MillanoImg3 from "../../assets/img/events/3.Milano Jewelry Week 2023/3.jpg";
import MillanoImg4 from "../../assets/img/events/3.Milano Jewelry Week 2023/4.jpg";
import MillanoImg5 from "../../assets/img/events/3.Milano Jewelry Week 2023/5.jpg";
import MillanoImg6 from "../../assets/img/events/3.Milano Jewelry Week 2023/6.jpg";
import MillanoImg7 from "../../assets/img/events/3.Milano Jewelry Week 2023/7.jpg";
import MillanoImg8 from "../../assets/img/events/3.Milano Jewelry Week 2023/8.jpg";
import MillanoImg9 from "../../assets/img/events/3.Milano Jewelry Week 2023/9.jpg";
import MillanoImg10 from "../../assets/img/events/3.Milano Jewelry Week 2023/10.jpg";
import MillanoImg11 from "../../assets/img/events/3.Milano Jewelry Week 2023/11.jpg";

import MassiraImg1 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/1.jpeg";
import MassiraImg2 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/2.jpeg";
import MassiraImg3 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/3.jpeg";
import MassiraImg4 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/4.jpeg";
import MassiraImg5 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/5.jpeg";
import MassiraImg5b from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/5b.jpeg";
import MassiraImg6 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/6.jpeg";
import MassiraImg6b from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/6b.jpeg";
import MassiraImg9 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/9.jpeg";
import MassiraImg10 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/10.jpeg";
import MassiraImg11 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/11.jpeg";
import MassiraImg12 from "../../assets/img/events/4.Massira Inclusive Srilanka 2018/12.jpeg";

import NigeriaImg1 from "../../assets/img/events/5.Men Fashion Week Nigeria 2021/1.jpg";
import NigeriaImg2 from "../../assets/img/events/5.Men Fashion Week Nigeria 2021/2.jpg";
import NigeriaImg3 from "../../assets/img/events/5.Men Fashion Week Nigeria 2021/3.jpg";
import NigeriaImg4 from "../../assets/img/events/5.Men Fashion Week Nigeria 2021/4.jpg";
import NigeriaImg5 from "../../assets/img/events/5.Men Fashion Week Nigeria 2021/5.jpg";
import NigeriaImg6 from "../../assets/img/events/5.Men Fashion Week Nigeria 2021/6.jpg";
import NigeriaImg7 from "../../assets/img/events/5.Men Fashion Week Nigeria 2021/7.jpg";

import CannesImg1 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/1.jpg";
import CannesImg2 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/2.jpg";
import CannesImg3 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/3.jpg";
import CannesImg4 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/4.jpg";
import CannesImg5 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/5.jpg";
import CannesImg6 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/6.jpg";
import CannesImg7 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/7.jpg";
import CannesImg8 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/8.jpg";
import CannesImg9 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/9.jpg";
import CannesImg10 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/10.jpg";
import CannesImg11 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/11.jpg";
import CannesImg12 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/12.jpg";
import CannesImg13 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/13.jpg";
import CannesImg14 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/14.jpg";
import CannesImg15 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/15.jpg";
import CannesImg16 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/16.jpg";
import CannesImg17 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/17.jpg";
import CannesImg18 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/18.jpg";
import CannesImg19 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/19.jpg";
import CannesImg20 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/20.jpg";
import CannesImg21 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/21.jpg";
import CannesImg22 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/22.jpg";
import CannesImg23 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/23.jpg";
import CannesImg24 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/24.jpg";
import CannesImg25 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/25.jpg";
import CannesImg26 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/26.jpg";
import CannesImg27 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/27.jpg";
import CannesImg29 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/29.jpg";
import CannesImg30 from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/30.jpg";
import CannesImg30a from "../../assets/img/events/6.Red Carpet Week 71st Cannes Film Festival 2018/30a.jpg";

// Store the images in an array for easy access
const cannesFilmFestivalImages = [
  CannesImg1, CannesImg2, CannesImg3, CannesImg4, CannesImg5, CannesImg6, CannesImg7,
  CannesImg8, CannesImg9, CannesImg10, CannesImg11, CannesImg12, CannesImg13, CannesImg14,
  CannesImg15, CannesImg16, CannesImg17, CannesImg18, CannesImg19, CannesImg20, CannesImg21,
  CannesImg22, CannesImg23, CannesImg24, CannesImg25, CannesImg26, CannesImg27,
  CannesImg29, CannesImg30,CannesImg30a
];

const nigeriaFashionWeekImages = [
  NigeriaImg1, NigeriaImg2, NigeriaImg3, NigeriaImg4, NigeriaImg5, NigeriaImg6, NigeriaImg7
];

const massiraInclusiveImages = [
  MassiraImg1, MassiraImg2, MassiraImg3, MassiraImg4, MassiraImg5, MassiraImg5b,
  MassiraImg6, MassiraImg6b, MassiraImg9, MassiraImg10, MassiraImg11, MassiraImg12
];


// Store the images in an array for easy access
const milanoJewelryWeekImages = [
  MillanoImg1, MillanoImg2, MillanoImg3, MillanoImg4, MillanoImg5, MillanoImg6, MillanoImg7,
  MillanoImg8, MillanoImg9, MillanoImg10, MillanoImg11
];
// import MillanoVideo1 from "../../assets/img/events/3.Milano Jewelry Week 2023/.jpg";


// Store the images in an array for easy access
const enchantingFabricsImages = [
  fabricImg1, fabricImg2, fabricImg3, fabricImg4, fabricImg5, fabricImg6, fabricImg7,
  fabricImg8, fabricImg9, fabricImg10, fabricImg11, fabricImg12, fabricImg13, fabricImg14
];

const thumbImg = [
  thumb1,thumb2,thumb3,thumb4,thumb5,thumb6
];

// Store the images in an array for easy access
const istanbulFashionWeekImages = [
  istanbunImg1, istanbunImg2, istanbunImg3, istanbunImg4, istanbunImg5,
  istanbunImg6, istanbunImg7, istanbunImg8, istanbunImg9, istanbunImg10, istanbunImg11
];



// const eventItems = [
//   { id: 1, title: 'Istanbul Modest Fashion Week', year: '2024', thumbUrl: thumb1 },
//   { id: 2, title: 'Enchanting Fabrics Uniting Cultures', year: '2023', thumbUrl: thumb2 },
//   { id: 3, title: 'Milano Jewelry Week', year: '2023', thumbUrl: thumb3 },
//   { id: 4, title: 'Men\'s Fashion Week Nigeria', year: '2021', thumbUrl: thumb5 },
//   { id: 5, title: 'Massira Inclusive Srilanka', year: '2018', thumbUrl: thumb4 },
//   { id: 6, title: '6.Red Carpet Week 71st Cannes Film Festival', year: '2018', thumbUrl: thumb6 },
// ];


// const Event: React.FC = () => (
//   <Row gutter={[32, 32]} justify="center">
//     {eventItems.map(item => (
//       <Col xs={24} sm={12} md={8} lg={8} key={item.id}>
//         <Card
//           hoverable
//           style={{ backgroundColor: '#333', border: '1px solid #FFD700', textAlign: 'center', color: '#FFD700' }}
//           cover={
//             <div style={{ position: 'relative' }}>
//               <img alt={item.title} src={item.thumbUrl} style={{ height:'500px',objectFit: 'cover', width: '100%' }} />
//               <div style={{
//                 position: 'absolute',
//                 bottom: 0,
//                 left: 0,
//                 width: '100%',
//                 padding: '10px',
//                 backgroundColor: 'rgba(0, 0, 0, 0.7)',
//                 color: '#FFD700',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}>
//                 <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.title}</span>
//                 <span style={{ fontSize: '14px' }}>{item.year}</span>
//               </div>
//             </div>
//           }
//         />
//       </Col>
//     ))}
//   </Row>
// );

// export default Event;
// /src/components/Event.tsx


const eventItems = [
  {
    id: 1,
    title: 'Istanbul Modest Fashion Week',
    year: '2024',
    thumbUrl: thumb1,
    images: [
      istanbunImg1, istanbunImg2, istanbunImg3, istanbunImg4, istanbunImg5,
      istanbunImg6, istanbunImg7, istanbunImg8, istanbunImg9, istanbunImg10,
      istanbunImg11
    ]
  },
  {
    id: 2,
    title: 'Enchanting Fabrics Uniting Cultures',
    year: '2023',
    thumbUrl: thumb2,
    images: [
      fabricImg1, fabricImg2, fabricImg3, fabricImg4, fabricImg5, fabricImg6,
      fabricImg7, fabricImg8, fabricImg9, fabricImg10, fabricImg11, fabricImg12,
      fabricImg13, fabricImg14
    ]
  },
  {
    id: 3,
    title: 'Milano Jewelry Week',
    year: '2023',
    thumbUrl: thumb3,
    videoUrl: '/video/video.mp4', // Update with actual path
    readMoreLink: 'https://www.milanojewelryweek.com/niharika-momtaz-2/',
    writeup: `1972 PUTS BANGLADESH ON THE MILAN MAP
      We are filled with excitement as we embark on our inaugural participation in Milano Jewelry Week 2023.
      MILANO JEWELRY WEEK, 2023 
      Booth: Artistar Jewels 
      Venue: Palazzo Bovara | Corso di Porta Venezia 51 Milan 
      Date: October 19th-22nd, 2023.`,
    images: [
      MillanoImg1, MillanoImg2, MillanoImg3, MillanoImg4, MillanoImg5, MillanoImg6,
      MillanoImg7, MillanoImg8, MillanoImg9, MillanoImg10, MillanoImg11
    ]
  },
  {
    id: 4,
    title: "Men's Fashion Week Nigeria",
    year: '2021',
    thumbUrl: thumb5,
    images: [
      NigeriaImg1, NigeriaImg2, NigeriaImg3, NigeriaImg4, NigeriaImg5,
      NigeriaImg6, NigeriaImg7
    ],
    writeup: `Private Viewing at Men’s Fashion Week Nigeria, Sub Saharan biggest menswear fashion week! 

“My debut show for any African Fashion event was a positively overwhelming experience.” - Niharika Momtaz  

The Men's Fashion Week Nigeria took place in Abuja on August 6th, spotlighting diversity within the African fashion realm. This event served as a vibrant platform for fashion and design experts to embrace mainstream clothing and jewelry trends in Africa.

During the Men's Fashion Week Nigeria in Abuja, Niharika Momtaz, a Dubai-based Bangladeshi designer, introduced her exquisite men's jewelry collection named "Love & Promise." This captivating collection beautifully blends contemporary elegance with a touch of whimsical nostalgia, skillfully intertwining art, culture, and history.

The event welcomed distinguished guests, including His Excellency Masudur Rahman, the High Commissioner of Bangladesh to Nigeria, along with his family, as well as dignitaries, celebrities, established designers, and fashion enthusiasts.
`,

  },
  {
    id: 5,
    title: 'Massira Inclusive Srilanka',
    year: '2018',
    thumbUrl: thumb4,
    images: [
      MassiraImg1, MassiraImg2, MassiraImg3, MassiraImg4, MassiraImg5,
      MassiraImg5b, MassiraImg6, MassiraImg6b, MassiraImg9, MassiraImg10,
      MassiraImg11, MassiraImg12
    ]
  },
  {
    id: 6,
    title: 'Red Carpet Week 71st Cannes Film Festival',
    year: '2018',
    thumbUrl: thumb6,
    images: [
      CannesImg1, CannesImg2, CannesImg3, CannesImg4, CannesImg5, CannesImg6,
      CannesImg7, CannesImg8, CannesImg9, CannesImg10, CannesImg11, CannesImg12,
      CannesImg13, CannesImg14, CannesImg15, CannesImg16, CannesImg17, CannesImg18,
      CannesImg19, CannesImg20, CannesImg21, CannesImg22, CannesImg23, CannesImg24,
      CannesImg25, CannesImg26, CannesImg27, CannesImg29, CannesImg30, CannesImg30a
    ]
  }
];

const Event: React.FC = () => {
  const navigate = useNavigate();

  const handleEventClick = (eventData: any) => {
    navigate(`/media/events/${eventData.id}`, { state: { event: eventData } });
  };

  return (
    <Row gutter={[32, 32]} justify="center">
      {eventItems.map(item => (
        <Col xs={24} sm={12} md={8} lg={8} key={item.id}>
          <Card
            hoverable
            onClick={() => handleEventClick(item)}
            style={{ backgroundColor: '#333', border: '1px solid #FFD700', color: '#FFD700' }}
            cover={
              <div style={{ position: 'relative' }}>
                <img alt={item.title} src={item.thumbUrl} style={{ height: '500px', objectFit: 'cover', width: '100%' }} />
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  padding: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: '#FFD700',
                  textAlign: 'center',
                }}>
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.title}</span>
                  <span style={{ fontSize: '14px' }}>{item.year}</span>
                </div>
              </div>
            }
          />
        </Col>
      ))}
    </Row>
  );
};

export default Event;