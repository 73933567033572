import React, { useState } from 'react';
import { Row, Col, Typography, Card, Modal, Divider, Space } from 'antd';
import img1 from '../assets/img/collaboration/1.jpg';
import img2 from '../assets/img/collaboration/3.jpg';
import img3 from '../assets/img/collaboration/4.png';

const { Title, Paragraph } = Typography;

const collaborations = [
  {
    id: 1,
    title: 'Collaboration with Artist Jamal Ahmed',
    imageUrl: img1,
    shortDescription: 'A joint collaboration with renowned artist Jamal Ahmed, exploring new artistic horizons.',
    longDescription: "Niharika Momtaz blends visual art with fashion. Art isn't just for walls anymore; she introduces a new bespoke collection. Dubai-based Bangladeshi designer Niharika Momtaz and eminent artist Jamal Ahmed collaborated to blend art with fashion for the first time ever, releasing the artist’s creativity from gallery walls to the elegance of textiles. This collection highlights Bangladeshi artistry, featuring high-quality Tassar silk sarees signed by the artist himself.",
    link: ['https://www.thedailystar.net/lifestyle/check-it-out/news/niharika-momtaz-blends-visual-art-fashion-1875400',],
  },
  {
    id: 2,
    title: 'Joined Forces with HE Farhan Sheikh Al Sayed',
    imageUrl: img2,
    shortDescription: 'Incorporating the stunning new Misbah series into the collection showcased in Doha, Qatar.',
    longDescription: "Niharika Momtaz joined forces with HE Farhan Sheikh Al Sayed, incorporating his new Misbah series into her collection and showcases in Doha, Qatar. The collaboration highlights the blend of cultural heritage and modern fashion.",
    
  },
  {
    id: 3,
    title: 'Collaborated with Miss Universal Beauty Pageant 2017',
    imageUrl: img3,
    videoUrl: '/video/5.mp4',
    shortDescription: 'Collaborated with Miss Universal Beauty Pageant 2017 to bring elegance to the stage.',
    longDescription: "Collaborating with Miss Universal Beauty Pageant 2017, Niharika Momtaz infused elegance and creativity into the fashion designs presented at the event, making a mark in the global fashion arena.",
   
  },
];

const Collaboration: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCollab, setSelectedCollab] = useState<any>(null);

  const showModal = (collab: any) => {
    setSelectedCollab(collab);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCollab(null);
  };

  return (
    <div >
      <Title level={2} style={{ color: '#FFD700', textAlign: 'center' }}>
        COLLABORATION
      </Title>
      <Divider style={{ borderColor: 'gold', margin: '0 auto',marginBottom: '10px' }} />
      
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '15px', // Space between buttons
          justifyContent: 'center', // Center the buttons
          alignItems: 'center',
          textAlign: 'center',
          width: '100%', // Make it responsive
          padding: '8px 0',
        }}
      >
        {collaborations.map((collab) => (
          <Card
            hoverable
            onClick={() => showModal(collab)}
            style={{ backgroundColor: '#333', height: '500px', width: "300px", borderColor: '#FFD700', color: '#FFD700', cursor: 'pointer', padding: '10px' }}
            cover={
              <img
                src={collab.imageUrl}
                alt={collab.title}
                style={{
                  width: '100%',
                  borderRadius: '5px 5px 0 0',
                  objectFit: 'contain',
                  height: '300px',
                }}
              />
            }
          >
            <Title level={4} style={{ color: '#FFD700' }}>{collab.title}</Title>
            <Paragraph style={{ color: '#fff' }}>{collab.shortDescription}</Paragraph>
          </Card>

        ))}

      </div>

      <Divider />
      {selectedCollab && (
        <Modal
          title={<Title level={3} style={{ color: '#FFD700', backgroundColor: '#1f1f1f' }}>{selectedCollab.title}</Title>}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          centered
          closeIcon={<span style={{ color: '#FFD700' }}>×</span>}
          styles={{
            content: { backgroundColor: '#1f1f1f', color: '#fff', textAlign: 'center' }, // turns the Modal red
          }}
        >
          {selectedCollab.videoUrl ? (
            <video
              controls
              style={{ width: '100%', borderRadius: '5px', marginBottom: '20px' }}
            >
              <source src={selectedCollab.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={selectedCollab.imageUrl}
              alt={selectedCollab.title}
              style={{ width: '100%', borderRadius: '5px', marginBottom: '20px' }}
            />
          )}
          <Paragraph style={{ color: '#fff' }}>{selectedCollab.longDescription}</Paragraph>
          {selectedCollab.link?.map((link: string, index: number) => (
            <div key={index} style={{ marginTop: '10px' }}>
              <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: '#FFD700' }}>
                News Link
              </a>
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default Collaboration;
