import React from 'react';
import { Typography,  List } from 'antd';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import "./css/Contact.css"
const { Title } = Typography;

const Contact: React.FC = () => {
  return (
    <div style={{ padding: '40px 20px', backgroundColor: '#0f0f0f', color: 'white' }}>
      <Title level={2} style={{ textAlign: 'center', color: '#FFD700', marginBottom: '20px' }}>
        Contact
      </Title>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          textAlign: 'left',
          width: '100%',
        }}
      >
        {/* Google Map Embed */}
        <div style={{ borderRadius: '8px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163512.6323643474!2d55.14900462546415!3d25.09668678974925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sbd!4v1681334303671!5m2!1sen!2sbd"
            height="300px"
            width="300px"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          />
        </div>

        {/* Contact Information */}
        <List
          size="large"
          style={{
            width: '300px',
            backgroundColor: '#1c1c1c',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
          }}
          bordered
          dataSource={[
            {
              icon: <PhoneOutlined style={{ fontSize: '20px', color: '#FFD700' }} />,
              title: 'Phone',
              content: (
                <div>
                  <a
                    href="tel:+971551785800"
                    style={{
                      color: 'white',
                      display: 'block',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap',
                      marginBottom: '8px',
                    }}
                    className="contact-link"
                  >
                    Dubai +971 55 178 5800
                  </a>
                  <a
                    href="tel:+8801711564862"
                    style={{
                      color: 'white',
                      display: 'block',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap',
                    }}
                    className="contact-link"
                  >
                    Dhaka +880 171 156 4862
                  </a>
                </div>
              ),
            },
            {
              icon: <MailOutlined style={{ fontSize: '20px', color: '#FFD700' }} />,
              title: 'Email',
              content: (
                <a
                  href="mailto:niharika.momtaz@gmail.com"
                  style={{ color: 'white', textDecoration: 'none', whiteSpace: 'nowrap' }}
                  className="contact-link"
                >
                  niharika.momtaz@gmail.com
                </a>
              ),
            },
          ]}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<span style={{ fontSize: '24px', marginRight: '10px' }}>{item.icon}</span>}
                title={<span style={{ color: '#FFD700', fontWeight: 'bold' }}>{item.title}</span>}
                description={<div style={{ color: 'white' }}>{item.content}</div>}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};


export default Contact;
