import React from 'react';
import { Layout, Space } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../assets/img/logo_b.png';
import ResponsiveMenu from './ResponsiveMenu';
import "./css/Header.css"
const { Header } = Layout;

const CustomHeader: React.FC = () => {
  return (
    <Header
      className="header"
      style={{
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 1000,
        height: 'auto',
        minHeight: '80px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0 0 0',
        background: 'linear-gradient(145deg, #1f1f1f, #0f0f0f)', // Gradient for 3D effect
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)', // Soft shadow for depth
        borderBottom: '1px solid rgba(255, 255, 255, 0.2)', // Optional border for a sharper edge
      }}
    >
      <RouterLink to="/" style={{ paddingBottom: "0px", marginBottom: "0px" }}>
        <img src={logo} alt="Logo" style={{ height: '60px', width: 'auto' }} /> {/* Adjust size for mobile */}
      </RouterLink>
      <ResponsiveMenu />
      <Space align="center" direction="vertical" size={0} > {/* Reduced size for minimal spacing */}

       
      </Space>
    </Header>
  );
};

export default CustomHeader;
