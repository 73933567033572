import React, { useEffect, useMemo, useState } from 'react';
import '../assets/css/custom.css';
import logo from '../assets/img/logo_b.png';
import FlexibleMenu from '../components/Menu';
import SocialMediaLinks from '../components/SocialMediaLinks';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
} from "@tsparticles/engine";

import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import CustomDivider from 'components/CustomDivider';
import { Divider } from 'antd';



const Home: React.FC = () => {
  const [init, setInit] = useState(false);
  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      background: {
        // Remove or comment out this color if you want to see the image background
        // color: {
        //   value: "#0d47a1", // Comment out or remove this line
        // },
      },
      fpsLimit: 150,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.out,
          },
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 200,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    [],
  );

  return (
    <div className="homepage-container">
      {/* Particle Background */}
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />
      {/* Main Content */}
      <div className="content">
        <img src={logo} alt="Logo" className="logo" />

        <FlexibleMenu useButtons={true}/>
        <Divider/>
        <CustomDivider/>
        <SocialMediaLinks usePatternOne={true} />
      </div>
    </div>
  );
};

export default Home;
