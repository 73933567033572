// /src/components/Celebrity.tsx
import React, { useRef, useEffect,useCallback  } from 'react';

import { Card, Col, Row ,Button} from 'antd';
import img1 from "../../assets/img/celebrity/Ambassador of Sweden Alexandra Berg von Linde.jpeg";
import img2 from "../../assets/img/celebrity/Camelia Ranga 2.jpeg";
import img3 from "../../assets/img/celebrity/Camelia Ranga 4.jpeg";
import img4 from "../../assets/img/celebrity/Camelia Ranga.jpeg";
import img5 from "../../assets/img/celebrity/Face of Tiffany & Co. and Versace 2017.jpeg";
import img6 from "../../assets/img/celebrity/Miss Wheelchair World 2017.jpeg";
import { ResponsiveContainer, StackedCarousel } from 'react-stacked-center-carousel';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


interface CelebrityItem {
  id: number;
  name: string;
  thumb: string;
}

const celebrityItems: CelebrityItem[] = [
  { id: 1, name: 'Ambassador of Sweden Alexandra Berg von Linde', thumb: img1 },
  { id: 2, name: 'Camelia Ranga', thumb: img2 },
  { id: 3, name: 'Camelia Ranga', thumb: img3 },
  { id: 4, name: 'Camelia Ranga', thumb: img4 },
  { id: 5, name: 'Face of Tiffany & Co. and Versace 2017', thumb: img5 },
  { id: 6, name: 'Miss Wheelchair World 2017', thumb: img6 },
];

const Celebrity: React.FC = () => {
  const carouselRef = useRef<StackedCarousel | undefined>(undefined);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startAutoPlay = useCallback(() => {
    intervalRef.current = setInterval(() => {
      carouselRef.current?.goNext();
    }, 3000); 
  }, []);

  const stopAutoPlay = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const handleNext = () => {
    stopAutoPlay();
    carouselRef.current?.goNext();
    startAutoPlay();
  };

  const handleBack = () => {
    stopAutoPlay();
    carouselRef.current?.goBack();
    startAutoPlay();
  };

  useEffect(() => {
    startAutoPlay();
    return () => stopAutoPlay(); // Clean up on component unmount
  }, [startAutoPlay, stopAutoPlay]);


  return (
    <Row gutter={[32, 32]} justify="center" style={{ marginTop: '20px', width: '100%' }}>
      <ResponsiveContainer
        carouselRef={carouselRef}
        render={(parentWidth: number,carouselRefft:any) => (
          <StackedCarousel
            ref={carouselRefft}
            data={celebrityItems}
            slideComponent={({ data, dataIndex ,isCenterSlide}) => {
              const { id, name, thumb } = data[dataIndex];
              const isMobile = parentWidth < 600;
              return (
                <div key={id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={thumb}
                    alt={name}
                    style={{
                      width: isMobile ? '200px' : '300px',
                      height: isMobile ? '300px' : '500px',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    }}
                  />
                   {isCenterSlide && (
                    <div style={{ color: '#FFD700', marginTop: '10px', textAlign: 'center' }}>{name}</div>
                  )}
                </div>
              );
            }}
            fadeDistance={0}
            transitionTime={600}
            carouselWidth={parentWidth}  // Dynamic width based on container
            slideWidth={parentWidth < 600 ? 200 : 250}   // Adjust slide width on mobile
            maxVisibleSlide={celebrityItems.length - 1}
          />
        )}
      />
      {/* Styled Arrow Navigation Buttons Below the Slider */}
      <Row justify="center" style={{ marginTop: '20px' }}>
        <Button
          onClick={handleBack}
          style={{
            marginRight: '10px',
            backgroundColor: '#333',
            color: '#FFD700',
            border: 'none',
            fontSize: '20px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
          }}
          icon={<LeftOutlined />}
        />
        <Button
          onClick={handleNext}
          style={{
            backgroundColor: '#333',
            color: '#FFD700',
            border: 'none',
            fontSize: '20px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
          }}
          icon={<RightOutlined />}
        />
      </Row>
    </Row>
  );
};
export default Celebrity;