import React, { useState } from 'react';
import { Row, Col, Typography, Button, Divider, Modal } from 'antd';
import img0 from '../assets/img/newsroom/0 gulf today 27 october.jpeg';
import img1 from '../assets/img/newsroom/1. dailystar_2024.jpeg';
import img2 from '../assets/img/newsroom/2. Colors_2024.jpeg';
import img3 from '../assets/img/newsroom/3. The_fame_dec_2023.jpeg';
import img4 from '../assets/img/newsroom/4.Gulf_today_dec_2023.jpeg';
import img5 from '../assets/img/newsroom/5. fame dec 2023.jpg';
import img6 from '../assets/img/newsroom/6. khaleej times Nov 2023.jpeg';
import img7 from '../assets/img/newsroom/7. Prothom alo oct 2023.jpeg';
import img8 from '../assets/img/newsroom/8. the business standard oct 2023.jpeg';
import img9 from '../assets/img/newsroom/9. daily star oct 2023.jpeg';
import img10 from '../assets/img/newsroom/10. hernet news sep 2023.jpeg';
import img11 from '../assets/img/newsroom/11. Officiallevogueng September 4 2023.jpg';
import img12 from '../assets/img/newsroom/12. metanews 2022.jpeg';
import img13 from '../assets/img/newsroom/13. daily star jun 2019 .jpeg';
import img14 from '../assets/img/newsroom/14. Daily star mar 2019.jpeg';
import img15 from '../assets/img/newsroom/15. Daily star feb 2019.jpeg';
import img16 from '../assets/img/newsroom/16. Daily star nov 2018.jpeg';
import img17 from '../assets/img/newsroom/17. daily star jan 2018.jpeg';
import img18 from '../assets/img/newsroom/18. srilankans weaving.jpeg';
import img19 from '../assets/img/newsroom/19.jpeg';
import img20 from '../assets/img/newsroom/20.jpeg';
import img21 from '../assets/img/newsroom/21.jpeg';
import img22 from '../assets/img/newsroom/22.jpeg';
import img23 from '../assets/img/newsroom/23.jpeg';
import img24 from '../assets/img/newsroom/24.jpeg';
import img25 from '../assets/img/newsroom/25. daily star 2021.jpg';
import img26 from '../assets/img/newsroom/26. vogue mini.jpeg';
import img27 from '../assets/img/newsroom/27.jpg';
import img28 from '../assets/img/newsroom/28.jpg';
import img29 from '../assets/img/newsroom/29.jpg';
import img30 from '../assets/img/newsroom/30.jpg';
import img31 from '../assets/img/newsroom/31.jpg';
import img32 from '../assets/img/newsroom/32. ntv.jpeg';
import img33 from '../assets/img/newsroom/33 daily star.jpeg';

const { Title } = Typography;
interface newsroomItem {
  id: number;
  title: string;
  imageUrl: string;
  link?: string;
  tags: string[];
}
const newsroomItems: newsroomItem[] = [
  {
    id: 0,
    title: 'Gulf today 27 october',
    imageUrl: img0,
    link: 'https://www.gulftoday.ae/culture/2024/10/27/niharika-momtazs-jewellery-designs-bring-shine-to-dubai-at-milano-show',
    tags: ['Gulf Today', 'Jewellery', '2024'],
  },
  {
    id: 1,
    title: 'The Daily Star - May 12, 2024',
    imageUrl: img1,
    link: 'https://www.thedailystar.net/lifestyle/news/niharika-momtaz-sweeps-istanbul-fashion-week-her-modern-and-trendy-collection-3608141',
    tags: ['Fashion', 'Istanbul Fashion Week', '2024'],
  },
  {
    id: 2,
    title: 'Colors Magazine - 2024',
    imageUrl: img2,
    link: 'https://www.colorsmagazine.net/niharika-momtaz-sweeps-istanbul-with-her-modern-modesty-and-trendy-collection/',
    tags: ['Fashion', 'Colors Magazine', '2024'],
  },
  {
    id: 3,
    title: 'The Fame - December 9, 2023',
    imageUrl: img3,
    link: 'https://thefamemagazine.com/niharika-momtaz-the-artistic-trailblazer-weaving-tradition-into-fashion/',
    tags: ['Fashion', 'The Fame Magazine', '2023'],
  },
  {
    id: 4,
    title: 'Gulf Today - December 2023',
    imageUrl: img4,
    link: 'https://www.gulftoday.ae/culture/2023/12/29/tracing-displacement-curated-by-niharika-momtaz-challenged-conventional-outlooks',
    tags: ['Culture', 'Gulf Today', '2023'],
  },
  {
    id: 5,
    title: 'The Fame Magazine',
    imageUrl: img5,
    link: 'https://thefamemagazine.com/introducing-the-fame-where-boldness-beauty-and-brilliance-unite/',
    tags: ['The Fame Magazine', '2023'],
  },
  {
    id: 6,
    title: 'Khaleej Times - November 29, 2023',
    imageUrl: img6,
    link: 'https://www.khaleejtimes.com/kt-network/niharika-momtaz-founder-of-1972-unveils-heritage-treasures',
    tags: ['Heritage', 'Khaleej Times', '2023'],
  },
  {
    id: 7,
    title: 'Prothom Alo - October 19, 2023',
    imageUrl: img7,
    link: 'https://www.prothomalo.com/lifestyle/fashion/mkcb109o5r',
    tags: ['Fashion', 'Prothom Alo', '2023'],
  },
  {
    id: 8,
    title: 'The Business Standard - October 15, 2023',
    imageUrl: img8,
    link: 'https://www.tbsnews.net/splash/designer-niharika-momtaz-takes-bangladeshi-fashion-qatar-719610',
    tags: ['Fashion', 'Business Standard', 'TBS News', 'Qatar', '2023'],
  },
  {
    id: 9,
    title: 'The Daily Star - October 12, 2023',
    imageUrl: img9,
    link: 'https://www.thedailystar.net/lifestyle/news/niharika-momtazs-fashion-show-wows-doha-3441431',
    tags: ['Fashion', 'Doha', 'The Daily Star', '2023'],
  },
  {
    id: 10,
    title: 'The Hernet News - September 2023',
    imageUrl: img10,
    link: 'https://hernet.news/?p=2759',
    tags: ['Hernet News', '2023'],
  },
  {
    id: 11,
    title: 'Officiallevogueng - September 4, 2023',
    imageUrl: img11,
    link: 'https://www.instagram.com/officiallevogueng/reel/CwxBAfqsJvc/',
    tags: ['Fashion', 'Vogue', '2023'],
  },
  {
    id: 12,
    title: 'Metanews - July 5, 2022',
    imageUrl: img12,
    link: 'https://metanews.com/meta-moina-engages-in-the-fashion-metaverse/',
    tags: ['Fashion', 'Metanews', '2022'],
  },
  {
    id: 13,
    title: 'The Daily Star - June 18, 2019',
    imageUrl: img13,
    link: 'https://www.thedailystar.net/lifestyle/news-flash/news/niharikas-latest-collection-cannes-2019-1758091',
    tags: ['Fashion', 'The Daily Star', 'Cannes', '2019'],
  },
  {
    id: 14,
    title: 'The Daily Star - March 12, 2019',
    imageUrl: img14,
    link: 'https://www.thedailystar.net/lifestyle/cover-story/news/bejewelled-debonair-1713634',
    tags: ['Lifestyle', 'The Daily Star', '2019'],
  },
  {
    id: 15,
    title: 'The Daily Star - February 24, 2019',
    imageUrl: img15,
    link: 'https://www.thedailystar.net/supplements/28th-anniversary-supplements/the-lifestyle-parenthesis/news/accessorise-1706194',
    tags: ['Lifestyle', 'The Daily Star', '2019'],
  },
  {
    id: 16,
    title: 'The Daily Star - November 27, 2018',
    imageUrl: img16,
    link: 'https://www.thedailystar.net/lifestyle/news-flash/news/fashion-embraces-the-differently-abled-1664986',
    tags: ['Fashion', 'Lifestyle', 'The Daily Star', '2018'],
  },
  {
    id: 17,
    title: 'The Daily Star - January 13, 2018',
    imageUrl: img17,
    link: 'https://www.thedailystar.net/lifestyle/ls-pick/niharikas-love-the-bold-1526818',
    tags: ['Lifestyle', 'The Daily Star', '2018'],
  },
  // Adding items from 18-31 with corresponding images
  {
    id: 18,
    title: 'Sri Lankan Weaving - 2023',
    imageUrl: img18,
    tags: ['Sri Lanka Weaving', '2023'],
  },
  {
    id: 19,
    title: 'Newsroom Coverage 19',
    imageUrl: img19,
    tags: ['Other'],
  },
  {
    id: 20,
    title: 'Newsroom Coverage 20',
    imageUrl: img20,
    tags: ['Other'],
  },
  {
    id: 21,
    title: 'Newsroom Coverage 21',
    imageUrl: img21,
    tags: ['Other'],
  },
  {
    id: 22,
    title: 'Newsroom Coverage 22',
    imageUrl: img22,
    tags: ['Other'],
  },
  {
    id: 23,
    title: 'Newsroom Coverage 23',
    imageUrl: img23,
    tags: ['Other'],
  },
  {
    id: 24,
    title: 'Newsroom Coverage 24',
    imageUrl: img24,
    tags: ['Other'],
  },
  {
    id: 25,
    title: 'The Daily Star - 2021',
    imageUrl: img25,
    tags: ['Fashion', 'The Daily Star', '2021'],
  },
  {
    id: 26,
    title: 'Vogue Mini - 2022',
    imageUrl: img26,
    tags: ['Vogue', '2022'],
  },
  {
    id: 27,
    title: 'Newsroom Image 27',
    imageUrl: img27,
    tags: ['Other'],
  },
  {
    id: 28,
    title: 'Newsroom Image 28',
    imageUrl: img28,
    tags: ['Other'],
  },
  {
    id: 29,
    title: 'Newsroom Image 29',
    imageUrl: img29,
    tags: ['Other'],
  },
  {
    id: 30,
    title: 'Newsroom Image 30',
    imageUrl: img30,
    tags: ['Other'],
  },
  {
    id: 31,
    title: 'Newsroom Image 31',
    imageUrl: img31,
    tags: ['Other'],
  },
  {
    id: 32,
    title: 'NTv',
    imageUrl: img32,
    link: 'https://uae.ntvbd.com/dubai/news-899862',
    tags: ['Other',"ntvbd","Dubai"],
  },
  {
    id: 33,
    title: 'Daily star 6 nov',
    imageUrl: img33,
    link: 'https://bangla.thedailystar.net/abroad/news-530441',
    tags: ['Other',"Daily Start"],
  },
];

// Dynamically generate unique tags from newsroom items
const allTags = Array.from(new Set(newsroomItems.flatMap(item => item.tags)));


const Newsroom: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  // Function to open the modal with the selected image
  const openModal = (imageUrl: string) => {
    setCurrentImage(imageUrl);
    setIsModalVisible(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalVisible(false);
    setCurrentImage('');
  };
  // Filter items based on the selected tag
  const filteredItems = selectedTag
    ? newsroomItems.filter(item => item.tags.includes(selectedTag))
    : newsroomItems;

  return (
    <div style={{ minHeight: '100vh' }}>
      <Title level={2} style={{ color: '#FFD700', textAlign: 'center' }}>
        Newsroom
      </Title>
      <Divider style={{ borderColor: 'gold', marginBottom: '20px' }} />

      {/* Tag Filter Buttons */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Button
          key="all"
          type={selectedTag === null ? 'primary' : 'default'}
          onClick={() => setSelectedTag(null)}
          style={{
            margin: '5px',
            backgroundColor: selectedTag === null ? '#FFD700' : '#333',
            color: selectedTag === null ? '#000' : '#FFD700',
            borderColor: 'gold',
          }}
        >
          All
        </Button>
        {allTags.map(tag => (
          <Button
            key={tag}
            type={selectedTag === tag ? 'primary' : 'default'}
            onClick={() => setSelectedTag(tag)}
            style={{
              margin: '5px',
              backgroundColor: selectedTag === tag ? '#FFD700' : '#333',
              color: selectedTag === tag ? '#000' : '#FFD700',
              borderColor: 'gold',
            }}
          >
            {tag}
          </Button>
        ))}
      </div>

      {/* Newsroom Gallery */}
      <Row gutter={[16, 16]} justify="center">
        {filteredItems.map((item) => (
          <Col xs={24} sm={12} md={8} lg={6} key={item.id}>
            <div>
              {item.link ? (
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img
                    alt={item.title}
                    src={item.imageUrl}
                    style={{ height:'500px',width: '100%', objectFit: 'cover' }}
                  />
                </a>
              ) : (
                <img
                  alt={item.title}
                  src={item.imageUrl}
                  style={{ height:'500px',width: '100%', objectFit: 'cover' }}
                  onClick={() => openModal(item.imageUrl)} // Open modal on click
                />
              )}
            </div>
          </Col>
        ))}
      </Row>
      {/* Modal for viewing the large image */}
      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={closeModal}



      >
        <img
          src={currentImage}
          alt="Large view"
          style={{ width: '100%', height: 'auto' }}
        />
      </Modal>
    </div>
  );
};

export default Newsroom;