import React from 'react';
import { Layout, Divider } from 'antd';
import { Outlet } from 'react-router-dom'; // Import Outlet for nested routes
import Header from './Header';
import Footer from './Footer';
import './css/Layout.css'
const { Content } = Layout;

const AppLayout: React.FC = () => {
    return (
        <Layout style={{
            backgroundColor: '#0f0f0f',
        }}>
            {/* Common Header */}
            <Header />
           
            {/* Dynamic Page Content */}
            <Content className="responsive-content">
                <Outlet />
            </Content>
            {/* Common Footer */}
            <Footer />
        </Layout>
    );
};

export default AppLayout;
