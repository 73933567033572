// /src/components/EventDetails.tsx
import React from 'react';
import { Carousel, Button, Typography, Card, Space } from 'antd';
import { useLocation, useNavigate, } from 'react-router-dom';
import './event_details.css'; // Import custom CSS for additional styling

const { Title, Paragraph } = Typography;

const EventDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const event = location.state?.event;

  if (!event) {
    return <p>Error: Event details not available.</p>;
  }

  const { title, year, videoUrl, readMoreLink, writeup, images } = event;

  return (
    <div style={{ color: '#FFD700' }}> {/* Gold text color */}
      <Button
        onClick={() => navigate(-1)}
        style={{
          margin: '20px auto',
          display: 'block',
          backgroundColor: '#FFD700',
          color: '#333',
          borderColor: '#FFD700',
          fontWeight: 'bold',
        }}
        type="primary"
        shape="round"
        size="large"
      >
        Back to Gallery
      </Button>
      <Title level={2} style={{ color: '#FFD700', textAlign: 'center' }}>{title} - {year}</Title>

      
      {videoUrl && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <video controls style={{ width: '80%', maxWidth: '700px', borderRadius: '10px' , height:"400px"}}>
            <source src={videoUrl} type="video/mp4" />
          </video>
          <Button
            type="primary"
            href={readMoreLink}
            target="_blank"
            style={{
              marginTop: '20px',
              color: '#FFD700',
              backgroundColor: '#333',
              borderColor: '#FFD700',
            }}
          >
            Read More
          </Button>
        </div>
      )}

      {/* Display write-up if available */}
      {writeup && (
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Card
            style={{
              maxWidth: '800px',
              backgroundColor: '#333',
              color: '#FFD700',
              border: '1px solid #FFD700',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            }}
          >
            <Title level={3} style={{ color: '#FFD700', textAlign: 'center', marginBottom: '15px' }}>Event Overview</Title>
            <Paragraph style={{ color: '#ffffff', textAlign: 'justify', lineHeight: '1.8' }}>
              {writeup}
            </Paragraph>
          </Card>
        </div>
      )}
      {/* Centered Image Carousel */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px', marginTop: '20px' }}>
        <div style={{ width: '400px', maxWidth: '700px' }}>
          <Carousel autoplay arrows style={{ height: '700px',justifyContent: 'center',alignItems: 'center', }}>
            {images.map((image: string, index: number) => (
              <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src={image} alt={`Slide ${index + 1}`} style={{ maxHeight: '700px', width: '100%',}} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};


export default EventDetails;
