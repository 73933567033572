import { Divider } from 'antd';
import '../assets/css/custom.css'; // Make sure to import your CSS file

const CustomDivider : React.FC = () =>{ 
    return <div className="custom-divider">
        <Divider
            style={{
                borderColor: 'gold',  // Set the line color to gold
                borderWidth: '1px',   // Set the line thickness
                opacity: 1,           // Ensure the line is fully visible
                backgroundColor: 'transparent', // Make the background transparent
                padding: 0,
                margin: '0 auto',      // Center the divider 
            }}
        />
    </div>
}

export default CustomDivider;

